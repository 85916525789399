import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import store from '@bertazzoni/common/src/store/index'
import { UserRole, isViewer, EnumRoleNumber } from '@bertazzoni/common/models/user.model'

Vue.use(VueRouter)

const NO_VIEWER = 'noViewer'

const routes: Array<RouteConfig> = [
  {
    path: '/page-not-found',
    component: () => import('@bertazzoni/common/src/components/NotFound.vue')
  },
  { path: '*', redirect: '/page-not-found' },
  {
    path: '/',
    component: () => import('@bertazzoni/common/src/views/Login/LoginStart.vue'),
    redirect: { name: 'Login' }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@bertazzoni/common/src/views/Login/Login.vue')
  },
  {
    path: '/login/reset-password',
    name: 'Resetpassword',
    props: true,
    component: () => import('@bertazzoni/common/src/views/Login/ResetPassword.vue')
  },
  {
    path: '/login/new-password/:id',
    name: 'Newpassword',
    component: () => import('@bertazzoni/common/src/views/Login/NewPassword.vue')
  },
  {
    path: '/module-list',
    name: 'ModuleList',
    component: () => import('@bertazzoni/back/views/Module/ModuleList.vue'),
    meta: { authorize: [UserRole.superAdmin] }
  },
  {
    path: '/module-consultation/:id',
    name: 'ModuleConsultation',
    component: () => import('@bertazzoni/back/views/Module/ModuleConsultation.vue'),
    meta: { authorize: [UserRole.superAdmin] }
  },
  {
    path: '/module-update/:id',
    name: 'UpdateModule',
    component: () => import('@bertazzoni/back/views/Module/UpdateModule.vue'),
    meta: { authorize: [UserRole.superAdmin, NO_VIEWER] }
  },
  {
    path: '/add-module',
    name: 'AddModule',
    component: () => import('@bertazzoni/back/views/Module/AddModule.vue'),
    meta: { authorize: [UserRole.superAdmin, NO_VIEWER] }
  },
  {
    path: '/module-stats/:id',
    name: 'ModuleStatistics',
    component: () => import('@bertazzoni/back/views/Stats/ModuleStatistics.vue'),
    meta: { authorize: [UserRole.superAdmin] }
  },
  {
    path: '/users',
    name: 'UserList',
    component: () => import('@bertazzoni/back/views/User/UserList.vue'),
    meta: { authorize: [UserRole.underAdmin] }
  },
  {
    path: '/add-user/:entityType/:entityId?',
    name: 'AddUser',
    component: () => import('@bertazzoni/back/views/User/AddUserContainer.vue'),
    meta: { authorize: [UserRole.underAdmin, NO_VIEWER] }
  },
  {
    path: '/update-user/:id',
    name: 'UpdateUser',
    component: () => import('@bertazzoni/back/views/User/UpdateUser.vue'),
    meta: { authorize: [UserRole.underAdmin, NO_VIEWER] }
  },
  {
    path: '/user-consultation/:id',
    name: 'UserConsultation',
    component: () => import('@bertazzoni/back/views/User/UserConsultation.vue'),
    meta: { authorize: [UserRole.underAdmin] }
  },
  {
    path: '/content-list',
    name: 'ContentList',
    component: () => import('@bertazzoni/back/views/Content/ContentList.vue'),
    meta: { authorize: [UserRole.superAdmin] }
  },
  {
    path: '/add-content',
    name: 'AddContent',
    component: () => import('@bertazzoni/back/views/Content/AddContent.vue'),
    meta: { authorize: [UserRole.superAdmin, NO_VIEWER] }
  },
  {
    path: '/content-update/:id',
    name: 'UpdateContent',
    component: () => import('@bertazzoni/back/views/Content/UpdateContent.vue'),
    meta: { authorize: [UserRole.superAdmin, NO_VIEWER] }
  },
  {
    path: '/content-consultation/:id',
    name: 'ContentConsultation',
    component: () => import('@bertazzoni/back/views/Content/ContentConsultation.vue'),
    meta: { authorize: [UserRole.superAdmin] }
  },
  {
    path: '/stats/',
    name: 'Stats',
    component: () => import('@bertazzoni/back/views/Stats/StatsContainer.vue'),
    meta: { authorize: [UserRole.underAdmin] }
  },
  {
    path: '/entity-list/:entityType',
    name: 'EntityList',
    component: () => import('@bertazzoni/back/views/Entities/EntityList.vue'),
    meta: { authorize: [UserRole.underAdmin] }
  },
  {
    path: '/add-entity/:entityType',
    name: 'AddEntity',
    component: () => import('@bertazzoni/back/views/Entities/AddEntity.vue'),
    meta: { authorize: [UserRole.underAdmin, NO_VIEWER] }
  },
  {
    path: '/new-communication',
    name: 'NewCom',
    component: () => import('@bertazzoni/back/views/Communications/NewCom.vue'),
    meta: { authorize: [UserRole.underAdmin, NO_VIEWER] }
  },
  {
    path: '/communication-list',
    name: 'CommunicationList',
    component: () => import('@bertazzoni/back/views/Communications/CommunicationList.vue'),
    meta: { authorize: [UserRole.underAdmin] }
  },
  {
    path: '/communication-custom',
    name: 'CustomCom',
    component: () => import('@bertazzoni/back/views/Communications/CustomCom.vue'),
    meta: { authorize: [UserRole.underAdmin, NO_VIEWER] }
  },
  {
    path: '/new-tags',
    name: 'NewTag',
    component: () => import('@bertazzoni/back/views/Tags/NewTag.vue'),
    meta: { authorize: [UserRole.superAdmin, NO_VIEWER] }
  },
  {
    path: '/tags',
    name: 'TagList',
    component: () => import('@bertazzoni/back/views/Tags/TagList.vue'),
    meta: { authorize: [UserRole.superAdmin, NO_VIEWER] }
  },
  {
    path: '/leader-board',
    name: 'Leaderboard',
    component: () => import('@bertazzoni/back/views/LeaderBoard/LeaderBoard.vue'),
    meta: { authorize: [UserRole.underAdmin] }
  },
  {
    path: '/leader-board/reset',
    name: 'ResetLeaderboard',
    component: () => import('@bertazzoni/back/views/LeaderBoard/NewChallenge.vue'),
    meta: { authorize: [UserRole.underAdmin, NO_VIEWER] }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

function isAuthorize(requiredAuthorisations: string[]): boolean {
  const currentUser = store.getters['User/currentUser']
  const roles = currentUser.roles

  if (requiredAuthorisations == undefined || requiredAuthorisations.length === 0) {
    return true
  }

  const noViewerOnThisRoute = requiredAuthorisations.includes(NO_VIEWER)
  if (noViewerOnThisRoute) {
    const isUserIsAViewer = isViewer(roles)
    if (isUserIsAViewer) {
      return false
    }
  }

  const minRolePointsRequired = EnumRoleNumber[requiredAuthorisations[0]]
  const userRolePoints = EnumRoleNumber[roles[0]]

  if (userRolePoints > minRolePointsRequired) {
    return false
  }
  return true
}

router.afterEach((to, from) => {
  if (from.path.match(/login/g)) {
    if (localStorage.getItem('reloaded')) {
      localStorage.removeItem('reloaded')
    } else {
      localStorage.setItem('reloaded', '1')
      location.reload()
    }
  }
})

router.beforeEach((to, from, next) => {
  const currentPath = to.path
  const loggedIn = store.getters['Auth/isLoggedIn']
  const isALoginPage =
    currentPath.match(/login/g) || currentPath.match(/page-not-found/) ? false : true

  if (isALoginPage) {
    isAuthorize(to.meta.authorize) ? next() : next('/page-not-found')
  }

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (isALoginPage && !loggedIn) {
    next('/login')
  } else {
    next()
  }
})

export default router
