export enum EntityType {
  bertazzoni = 'bertazzoni',
  country = 'country',
  distributor = 'distributor',
  superDealer = 'superDealer',
  dealer = 'dealer'
}

export enum EntityTypeIndex {
  bertazzoni,
  country,
  distributor,
  superDealer,
  dealer
}

export class NewEntityDto {
  entityName: string
  type: EntityType
  languages: string[]
  enable: boolean
  parent: string

  constructor() {
    this.entityName = ''
    this.type = '' as EntityType
    this.languages = []
    this.enable = true
    this.parent = ''
  }

  getParentType(): EntityType {
    const indexEnum = EntityTypeIndex[this.type]
    return EntityType[EntityTypeIndex[indexEnum - 1]]
  }
}

export class Entity {
  _id: string
  enable: boolean
  languages: string[]
  type: EntityType
  ancestors: string[]
  parent: string
}
export interface TagAccess {
  _id: string
  parents: string[]
}
