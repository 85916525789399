// TODO: group all user related api calls here

import axios from 'axios'
import { User, Insight, UpdateUserDto, NewUserDto } from '@bertazzoni/common/src/models/user.model'
import store from '@bertazzoni/common/src/store/index'
import { buildUrl, PaginationData } from '@bertazzoni/common/src/helpers/urlFactory'
const API_URL = process.env.VUE_APP_API_ENDPOINT + '/users'

class UsersService {
  findAll(): Promise<User[]> {
    return axios.get(`${API_URL}/findAll`).then((response) => {
      return response.data
    })
  }

  findOne(id: string): Promise<User> {
    return axios.get(`${API_URL}/findOne/${id}`).then((response) => {
      return response.data
    })
  }

  updateOne(id: string, user: UpdateUserDto): Promise<User> {
    return axios.put(`${API_URL}/updateOne/${id}`, user)
  }

  removeOne(id: string): Promise<void> {
    return axios.delete(`${API_URL}/deleteOne/${id}`)
  }

  async updateMyInsights(data: Insight): Promise<User> {
    try {
      let url = `${API_URL}/updateMyInsights/${data.moduleId}?type=${data.type}`
      if (data.type !== 'follow') url = `${url}&contentId=${data.contentId}`
      const response = await axios.put(url)

      sessionStorage.setItem('currentUser', JSON.stringify(response.data))
      store.commit('User/setUser')
    } catch (error) {
      return error
    }
  }

  async findMe(token?: string): Promise<User> {
    const response = await axios.get(
      `${API_URL}/findMe`,
      token ? { headers: { Authorization: `Bearer ${token}` } } : {}
    )
    sessionStorage.setItem('currentUser', JSON.stringify(response.data))
    store.commit('User/setUser')
    return response.data
  }

  async findAllPaginated(
    data: PaginationData
  ): Promise<{ limit: number; offset: number; results: User[]; total: number }> {
    let url = `${API_URL}/findAllPaginated/filter?limit=${data.limit}`
    url = buildUrl(data, url)
    const response = await axios.get(url)
    return response.data
  }

  async createOne(user: NewUserDto): Promise<User> {
    const response = await axios.post(`${API_URL}/createOne`, user)
    return response.data
  }

  async disableOne(userId: string): Promise<void> {
    await axios.put(`${API_URL}/disableUser/${userId}`)
  }

  async enableOne(userId: string): Promise<void> {
    await axios.put(`${API_URL}/enableUser/${userId}`)
  }

  async changeUserLanguageBy(newLanguage: string): Promise<void> {
    await axios.put(`${API_URL}/changeMyLanguage/${newLanguage}`)
  }

  async getCSV(): Promise<string> {
    return axios.get(`${API_URL}/export/`).then((response) => {
      const blob = new Blob([response.data], { type: 'text/csv' })
      return window.URL.createObjectURL(blob)
    })
  }
}

export default new UsersService()
