




































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class RadioButton extends Vue {
  @Prop({ required: true }) id!: string
  @Prop({ default: '' }) vmodel!: string | number
  @Prop({ default: null }) value!: string | number
  @Prop({ default: null }) checked!: boolean
  @Prop({ default: false }) disabled!: boolean
  private focused = false
  private vmodelValue = this.vmodel

  changeValue(): void {
    this.$emit('change-value', this.value)
  }

  @Watch('vmodel')
  getVmodelvalue(newValue: string | number) {
    this.vmodelValue = newValue
  }
}
