import axios from 'axios'

// todo : add in .env
const API_URL = process.env.VUE_APP_API_ENDPOINT + '/auth' // dynamically change the first part for whatever environment the code is running in

class AuthService {
  async login(username: string, password: string) {
    try {
      username = username.toLowerCase()
      const response = await axios.post(`${API_URL}/login`, {
        username,
        password
      })
      return response.data.access_token
    } catch (error) {
      return error
    }
  }

  logout() {
    sessionStorage.removeItem('token')
    localStorage.removeItem('token')
    sessionStorage.removeItem('currentUser')
    localStorage.removeItem('currentUser')
  }

  async resetPw(email: string): Promise<void> {
    return axios.post(`${API_URL}/reset_pw/user/${email}`)
  }

  async newPw(id: string, newPassword: string, token: string): Promise<void> {
    return axios.post(`${API_URL}/new_pw/${id}?token=${token}`, { newPassword })
  }

  async confirmSignUp(id: string, newPassword: string, token: string): Promise<void> {
    return await axios.put(`${API_URL}/confirmSignUp/${id}?token=${token}`, {
      newPassword: newPassword
    })
  }
}

export default new AuthService()
