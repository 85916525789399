






















































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { EntityType } from '@bertazzoni/common/src/models/entity.model'
import RadioButton from '@bertazzoni/common/src/components/RadioButton.vue'

@Component({
  components: {
    RadioButton
  },
  name: 'ChooseEntityNavModal'
})
export default class ChooseEntityNavModal extends Vue {
  selectedEntity: EntityType = null
  possibleEntities = {
    dealer: EntityType.dealer,
    country: EntityType.country,
    distributor: EntityType.distributor,
    superDealer: EntityType.superDealer
  }

  @Prop({ required: true }) nextRoute!: string
  close() {
    this.$emit('close')
  }
  confirmation() {
    if (!this.selectedEntity) throw new Error('Must choose one')
    this.$router.push(`/add-user/${this.selectedEntity}`)
    this.$emit('confirmation')
  }

  setRadioButton(newValue: EntityType): void {
    this.selectedEntity = newValue
  }
}
