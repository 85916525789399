import Vue from 'vue'
import Vuex from 'vuex'

import Auth from '@bertazzoni/common/src/store/modules/auth.module'
import User from '@bertazzoni/common/src/store/modules/user.module'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  getters: {},
  modules: {
    Auth,
    User
  }
})