import { EntityType } from '@bertazzoni/common/src/models/entity.model'

export type PaginationData = {
  limit: number
  offset?: number
  title?: string
  sorting?: string
  sortingField?: string
  type?: string
  countries?: string
  searchValue?: string
  searchingText?: string
  email?: string
  entityType?: EntityType
  language?: string
}

export function buildUrl(data: PaginationData, url: string): string {
  if (data.offset !== null && data.offset !== undefined) url += `&offset=${data.offset}`
  if (data.title && data.title !== '') url += `&title=${data.title}`
  if (data.sorting && data.sorting !== '') url += `&sorting=${data.sorting}`
  if (data.sortingField && data.sortingField !== '') url += `&sortingField=${data.sortingField}`
  if (data.type && data.type !== '') url += `&type=${data.type}`
  if (data.countries && data.countries !== '') url += `&countries=${data.countries}`
  if (data.email && data.email !== '') url += `&email=${data.email}`
  if (data.searchValue && data.searchValue !== '') url += `&searchValue=${data.searchValue}`
  if (data.searchingText && data.searchingText !== '') url += `&searchingText=${data.searchingText}`
  if (data.entityType) url += `&entityType=${data.entityType}`
  if (data.language) url += `&language=${data.language}`
  return url
}

export function buildTagUrl(tagNames: string[], url: string): string {
  if (tagNames.length > 0) {
    url += `?tagNames=`
    tagNames.map((tag: string, index) => {
      url += `${tag}`
      if (tagNames.length > 1 && index !== tagNames.length - 1) url += `,`
    })
  }
  return url
}
