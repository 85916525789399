import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators'
import UsersService from '@bertazzoni/common/src/services/UsersService'
import { User, Insight } from '@bertazzoni/common/src/models/user.model'

const storedCurrentUser = sessionStorage.getItem('currentUser')

@Module({ namespaced: true })
class Users extends VuexModule {
  public user = storedCurrentUser ? JSON.parse(storedCurrentUser) : storedCurrentUser

  get currentUser(): string {
    return this.user
  }

  @Mutation
  removeUser(): void {
    this.user = null
  }

  @Mutation
  setUser(): void {
    this.user = sessionStorage.getItem('currentUser')
      ? JSON.parse(sessionStorage.getItem('currentUser'))
      : null
  }

  @Action({ rawError: true })
  async findAll(): Promise<any> {
    try {
      const response = await UsersService.findAll()
      if (response) {
        return Promise.resolve(response)
      }
    } catch (error) {
      const message = error.message || error.toString()
      return Promise.reject(message)
    }
  }

  @Action({ rawError: true })
  async findOne(id: string): Promise<any> {
    try {
      const response = await UsersService.findOne(id)
      if (response) {
        return Promise.resolve(response)
      }
    } catch (error) {
      const message = error.message || error.toString()
      return Promise.reject(message)
    }
  }

  @Action({ rawError: true })
  async updateMyInsights(data: Insight): Promise<any> {
    try {
      const response = await UsersService.updateMyInsights(data)
      if (response) {
        return Promise.resolve(response)
      }
    } catch (error) {
      const message = error.message || error.toString()
      return Promise.reject(message)
    }
  }

  @Action({ rawError: true })
  async findMe(token: string): Promise<any> {
    try {
      const response = await UsersService.findMe(token)
      if (response) {
        return Promise.resolve(response)
      }
    } catch (error) {
      const message = error.message || error.toString()
      return Promise.reject(message)
    }
  }
}

export default Users
