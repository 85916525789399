




































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import ChooseEntityModal from '@bertazzoni/back/views/User/ChooseEntityModal.vue'
// import store from '@bertazzoni/common/src/store/index'

@Component({
  components: {
    ChooseEntityModal
  }
})
export default class SideNavMenu extends Vue {
  private menuType = 'learning'
  private showChooseEntityModal = false
  // get typeUser(): string {
  //   return store.getters['User/currentUser'] ? store.getters['User/currentUser'].type : null
  // }
  goModuleList() {
    this.$router.push('/module-list')
  }
  goAddModule() {
    this.$router.push('/add-module')
  }
  goContentList() {
    this.$router.push('/content-list')
  }
  goAddContent() {
    this.$router.push('/add-content')
  }
  goAddUser() {
    this.showChooseEntityModal = true
    // this.$router.push('/add-content')
  }
  goToAddUser() {
    this.$router.push('/add-content')
  }

  goUserList() {
    this.$router.push('/users')
  }
  goCountryList() {
    this.$router.push('/entity-list/country')
  }
  goDistributorList() {
    this.$router.push('/entity-list/distributor')
  }
  goSuperDealerList() {
    this.$router.push('/entity-list/superDealer')
  }
  goDealerList() {
    this.$router.push('/entity-list/dealer')
  }
  goNewCom() {
    this.$router.push('/new-communication')
  }
  goComList() {
    this.$router.push('/communication-list')
  }
  goCustomCom() {
    this.$router.push('/communication-custom')
  }
  goNewTag() {
    this.$router.push('/new-tags')
  }
  goTagList() {
    this.$router.push('/tags')
  }
  @Watch('$route.path')
  newMenu(newValue: string): void {
    if (
      newValue.match(/module-/g) ||
      newValue.match(/add-module((\/?))$/) ||
      newValue.match(/content-/g) ||
      newValue.match(/add-content((\/?))$/)
    ) {
      this.menuType = 'learning'
    } else if (
      newValue.match(/user-/g) ||
      newValue.match(/add-user/g) ||
      newValue.match(/update-user/g) ||
      newValue.match(/users((\/?))$/) ||
      newValue.match(/entity-/g)
    ) {
      this.menuType = 'user'
    } else if (newValue.match(/communication-/g) || newValue.match(/new-communication/g)) {
      this.menuType = 'communication'
    } else if (newValue.match(/tags((\/?))$/) || newValue.match(/new-tags((\/?))$/)) {
      this.menuType = 'tag'
    } else {
      this.menuType = ''
    }
  }
}
