import axios from 'axios'
import store from '@bertazzoni/common/src/store/index'
import AuthService from '@bertazzoni/common/src/services/AuthService'
import { UserError } from '@bertazzoni/common/src/models/user.error'
import EventBus from '@bertazzoni/common/src/helpers/eventBus'

const API_URL = process.env.VUE_APP_API_ENDPOINT

function verifyExpiredToken(errorDTO: any) {
  // TODO : CHANGE THAT
  if (errorDTO.config.url != `${API_URL}/auth/login`) {
    if (errorDTO.config.url === `${API_URL}/users/findMe`) {
      AuthService.logout()
    } else {
      return axios.get(`${API_URL}/users/findMe`).then((response) => {
        return response.data
      })
    }
  }
}

function handleErrors(errorDTO: any) {
  const error = new UserError(errorDTO.data.error)
  switch (error.statusCode) {
    case 400: {
      return error
    }
    case 401: {
      verifyExpiredToken(errorDTO)
      return error
    }
    case 403: { 
      return error
    }
    case 404: {
      const path: string = errorDTO.data.path
      const ressource = path.split('/', 2).pop()
      error.message = `${ressource.toUpperCase()} - Not found`
      return error 
    }
    case 409: {
      return error
    }
    default: {
      return new UserError({ statusCode: 999, message: 'Une erreur est survenue' })
    }
  }
}

export default function setup() {
  axios.interceptors.request.use(
    function(config) {
      config.headers['x-api-key'] = process.env.VUE_APP_API_KEY
      const token = store.getters['Auth/isLoggedIn'] ? store.getters['Auth/sessionToken'] : null
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
      return config
    },
    function(err) {
      return Promise.reject(err)
    }
  )

  axios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      const apiError: UserError = handleErrors(error.response)
      if (error.response && error.response.data) {
        EventBus.$emit('Error', apiError)
        return Promise.reject(apiError)
      }
      return Promise.reject(error.message)
    }
  )
}
