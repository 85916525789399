import { EntityType } from './entity.model'

export type StartedModule = {
  moduleId: string
  finishedContents: Array<string>
  allContentsStarted: boolean
}

export type UserScore = {
  moduleId: string
  score: number
  correctRate: number
  retake: number
  finishDate: Date
  startDate: Date
  canReTake: boolean
}

export type UpdateInsightType = 'follow' | 'start' | 'complete'

export type UserCredentials = { username: string; password: string; stayConnected: boolean }

export type Insight = { moduleId: string; type: UpdateInsightType; contentId: string }

export enum UserType {
  bertazzoniAdmin = 'bertazzoniAdmin',
  countryAdmin = 'countryAdmin',
  distributorRep = 'distributorRep',
  salesRep = 'salesRep',
  superDealerRep = 'superDealerRep',
  seller = 'seller'
}

export enum UserRole {
  superAdmin = 'superAdmin',
  admin = 'admin',
  underAdmin = 'underAdmin',
  endUser = 'endUser',
  viewer = 'viewer'
}
export enum EnumRoleNumber {
  superAdmin,
  admin,
  underAdmin,
  endUser
}

export function isViewer(roles: string[]): boolean {
  return roles.includes('viewer')
}
export function isNoViewer(roles: string[]): boolean {
  return roles.includes('noViewer')
}

export class User {
  _id: string
  email: string
  enable: boolean
  linkedEntityId: Array<string>
  roles: Array<UserRole>
  respId: string
  language: string
  firstName: string
  lastName: string
  isViewer: boolean
  type?: UserType
  insightsModule: {
    followed: Array<string>
    started: Array<StartedModule>
    completed: Array<{ moduleId: string; userScore: UserScore }>
  }
  totalScore: number
}

export class NewUserDto {
  email: string
  entityAccess: string
  entityType: EntityType
  language: string
  type: UserType
  isViewer: boolean
  lastName: string
  firstName: string

  constructor() {
    this.email = ''
    this.entityAccess = ''
    this.entityType = null
    this.language = ''
    this.type = null
    this.isViewer = false
    this.lastName = ''
    this.firstName = ''
  }
}

export class UpdateUserDto {
  email: string
  entityAccess: string
  language: string
  userType: UserType
  isViewer: boolean
  lastName: string
  firstName: string

  constructor() {
    this.email = ''
    this.entityAccess = ''
    this.language = ''
    this.userType = null
    this.isViewer = false
    this.lastName = ''
    this.firstName = ''
  }

  buildDtoFromUser(user: User) {
    this.email = user.email
    this.entityAccess = user.linkedEntityId[0]
    this.language = user.language
    this.userType = user.type
    this.isViewer = user.isViewer
    this.lastName = user.lastName
    this.firstName = user.firstName
  }
}
