
























import SideNavMenu from '@bertazzoni/back/components/SideNavMenu.vue'
import navMenu from '@bertazzoni/back/components/navMenu.vue'
import Icons from '@bertazzoni/common/src/components/Icons.vue'
import Toast from '@bertazzoni/back/components/Toast.vue'
import { Component, Vue, Watch } from 'vue-property-decorator'
import definePermissions from '@bertazzoni/common/src/helpers/permissions'

definePermissions()
@Component({
  components: {
    SideNavMenu,
    navMenu,
    Icons,
    Toast
  }
})
export default class App extends Vue {
  private showTemplate = true

  @Watch('$route.path')
  newPage(newValue: string): void {
    this.showTemplate = newValue.match(/login/g) || newValue.match(/page-not-found/) ? false : true
  }

  scrollToTop(): void {
    window.scrollTo(0, 0)
  }
  get showScroller(): boolean {
    return window.scrollY > 150
  }
}
