













































































































































































































































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Icons extends Vue {
  @Prop({ required: true }) name!: string
}
