
















import { Component, Vue, Watch } from 'vue-property-decorator'
import Icons from '@bertazzoni/common/src/components/Icons.vue'
import { UserError } from '@bertazzoni/common/src/models/user.error'
import EventBus from '@bertazzoni/common/src/helpers/eventBus'

@Component({
  components: { Icons }
})
export default class Toast extends Vue {
  private errorList: string[] = []

  created() {
    EventBus.$on('Error', this.newError)
    EventBus.$on('close', this.close)
  }

  private delay(ms) {
    new Promise((resolve) => setTimeout(resolve, ms))
  }

  async newError(newValue: UserError): Promise<void> {
    if (newValue.message) {
      if (typeof newValue.message === 'string') {
        this.errorList.push(newValue.message)
      } else {
        newValue.message.map((message: string) => this.errorList.push(message))
      }
    }
    this.autoCloseToasters()
  }
  async autoCloseToasters(): Promise<void> {
    await this.delay(5000)
    this.close(0)
  }

  close(index: number | string): void {
    if (typeof index === 'number') {
      this.errorList.splice(index, 1)
    } else {
      this.errorList = []
    }
  }

  @Watch('$route.path')
  newPage(): void {
    this.errorList = []
  }
}
