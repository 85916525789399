import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import AuthService from '@bertazzoni/common/src/services/AuthService'
import UsersService from '@bertazzoni/common/src/services/UsersService'
import { UserError } from '@bertazzoni/common/src/models/user.error'
import { UserCredentials, UserRole } from '@bertazzoni/common/src/models/user.model'
import { From } from '@bertazzoni/common/src/helpers/enums/from'

const storedToken = localStorage.getItem('token')
  ? localStorage.getItem('token')
  : sessionStorage.getItem('token')

function hasAccess(token: string, from: From): Promise<boolean> {
  return UsersService.findMe(token).then(
    (success) => {
      switch (from) {
        case From.frontOffice:
          if (
            success.roles.indexOf(UserRole.endUser) != -1 &&
            success.type != 'bertazzoniAdmin' &&
            success.enable
          ) {
            return true
          }
          return false
        case From.backOffce:
          if (
            (success.roles.indexOf(UserRole.admin) != -1 ||
              success.roles.indexOf(UserRole.superAdmin) != -1 ||
              success.roles.indexOf(UserRole.underAdmin) != -1) &&
            success.enable
          ) {
            return true
          }
          return false
      }
    },
    (error) => {
      error.message = 'Password incorect. Try again or reset your password'
      return Promise.reject(error)
    }
  )
}

@Module({ namespaced: true })
class Auth extends VuexModule {
  private status = storedToken ? { loggedIn: true } : { loggedIn: false } // if we find a user, status.loggedIn is true
  private token = storedToken ? JSON.parse(storedToken) : null // parse the storedToken as an object, if it existsv

  @Mutation
  public loginSuccess(token: string): void {
    // set the user to the one passed as a parameter. Also, set the status to loggedIn = true
    this.status.loggedIn = true
    this.token = token
  }

  @Mutation
  public loginFailure(): void {
    // set the status to loggedIn = false and remove any reference to a user after a failed login
    this.status.loggedIn = false
    this.token = null
  }

  @Mutation
  public logout(): void {
    // set the status to loggedIn = false and remove any reference to a user after a logout
    this.status.loggedIn = false
    this.token = null
  }

  @Action({ rawError: true })
  async signIn(userCredentials: UserCredentials): Promise<void> {
    // call the AuthService to handle the login api call
    try {
      const accessToken = await AuthService.login(
        userCredentials.username,
        userCredentials.password
      )

      const from = this.context.rootGetters['Common/from']
      const canAccess = await hasAccess(accessToken, from)
      if (accessToken && canAccess) {
        sessionStorage.setItem('token', JSON.stringify(accessToken))
        if (userCredentials.stayConnected) {
          localStorage.setItem('token', JSON.stringify(accessToken))
        }
        this.context.commit('loginSuccess', accessToken) // calls the loginSuccess Mutation
        return Promise.resolve()
      } else {
        throw new UserError({
          statusCode: 999,
          message: 'You do not have the required permissions to access this website'
        })
      }
    } catch (error) {
      this.context.commit('loginFailure') // calls the loginFailure Mutation
      return Promise.reject(error.message)
    }
  }

  @Action
  signOut(): void {
    // call the AuthService to handle the logout api call
    AuthService.logout()
    this.context.commit('logout')
  }

  get isLoggedIn(): boolean {
    // access the status.loggedIn value
    return this.status.loggedIn
  }

  get sessionToken(): string {
    return this.token
  }
}

export default Auth
