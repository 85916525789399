import Vue from 'vue'
import App from './App.vue'
import router from '@bertazzoni/back/router/index'
import store from '@bertazzoni/common/src/store/index'
import interceptorsSetup from '@bertazzoni/common/src/helpers/interceptors'
import VeeValidate from 'vee-validate'
import '@bertazzoni/back/assets/css/tailwind.css'
import { From } from '@bertazzoni/common/src/helpers/enums/from'
import VueShepherd from 'vue-shepherd'
Vue.config.productionTip = false

store.registerModule('Common', {
  state: {
    Common: {
      from: From.backOffce
    }
  },
  mutations: {},
  actions: {},
  getters: {
    'Common/from': (state) => state.Common.from
  }
})
// importing the helper

Vue.use(VueShepherd)

// and running it somewhere here
interceptorsSetup()
Vue.use(VeeValidate, {
  events: 'blur'
})
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
