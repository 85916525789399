























import { Component, Vue } from 'vue-property-decorator'
import { UserType } from '@bertazzoni/common/src/models/user.model'
// import store from '@bertazzoni/common/src/store/index'
// import EventBus from '@bertazzoni/common/src/helpers/eventBus'

@Component
export default class NavMenu extends Vue {
  // private permissionSet = false
  // created() {
  //   EventBus.$on('permissionsLoad', this.permissionsLoad)
  // }
  // get typeUser(): string {
  //   return store.getters['User/currentUser'] ? store.getters['User/currentUser'].type : null
  // }
  // permissionsLoad(load: boolean): void {
  //   this.permissionSet = load
  // }
  goHome(): void {
    if (
      this.$store.getters['User/currentUser'] &&
      (this.$store.getters['User/currentUser'].type === UserType.bertazzoniAdmin ||
        this.$store.getters['User/currentUser'].type === UserType.countryAdmin)
    ) {
      this.$router.push('/module-list')
    } else {
      this.$router.push('/users')
    }
  }
  goToUserManagement(): void {
    this.$router.replace('/users')
  }
  goToLearningMaterial(): void {
    this.$router.replace('/module-list')
  }
  goToStats(): void {
    this.$router.replace('/stats')
  }
  goToCommunications(): void {
    this.$router.replace('/new-communication')
  }
  goToLeaderboard(): void {
    this.$router.replace('/leader-board')
  }
  goToTags(): void {
    this.$router.replace('/tags')
  }
  logout(): void {
    this.$store.dispatch('Auth/signOut')
    this.$router.replace('/login')
  }
}
