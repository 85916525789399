export function isErrorNotFound(error: UserError): boolean {
  return error.statusCode === 404
}
export class UserError {
  id: number
  statusCode?: number
  message: string | string[]

  constructor(errorDTO: { statusCode?: number; message: string }) {
    this.id = Date.now()
    this.statusCode = errorDTO.statusCode ? errorDTO.statusCode : null
    this.message = errorDTO.message
  }
}

