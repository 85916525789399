import Vue from 'vue'
import VueAcl from 'vue-browser-acl'
import store from '@bertazzoni/common/src/store/index'
import { UserType } from '@bertazzoni/common/src/models/user.model'
// import EventBus from '@bertazzoni/common/src/helpers/eventBus'

export default function definePermissions() {
  Vue.use(
    VueAcl,
    store.getters['User/currentUser'] ? store.getters['User/currentUser'] : {},
    (acl) => {
      // Module
      acl.rule(['create', 'update', 'delete'], 'module', (user) => {
        return (
          [UserType.bertazzoniAdmin, UserType.countryAdmin].some(
            (element) => element == user.type
          ) && !user.isViewer
        )
      })
      acl.rule('read', 'module', (user) => {
        return [UserType.bertazzoniAdmin, UserType.countryAdmin].some(
          (element) => element == user.type
        )
      })
      // Content
      acl.rule(['create', 'update', 'delete'], 'content', (user) => {
        return (
          [UserType.bertazzoniAdmin, UserType.countryAdmin].some(
            (element) => element == user.type
          ) && !user.isViewer
        )
      })
      acl.rule('read', 'content', (user) => {
        return [UserType.bertazzoniAdmin, UserType.countryAdmin].some(
          (element) => element == user.type
        )
      })
      // Quiz
      acl.rule(['create', 'update', 'delete'], 'quiz', (user) => {
        return (
          [UserType.bertazzoniAdmin, UserType.countryAdmin].some(
            (element) => element == user.type
          ) && !user.isViewer
        )
      })
      acl.rule('read', 'quiz', (user) => {
        return [UserType.bertazzoniAdmin, UserType.countryAdmin].some(
          (element) => element == user.type
        )
      })
      // Country Admin
      acl.rule(['create', 'update', 'enable', 'disable', 'delete'], 'countryAdmin', (user) => {
        return [UserType.bertazzoniAdmin].some((element) => element == user.type) && !user.isViewer
      })
      acl.rule('read', 'countryAdmin', (user) => {
        return [UserType.bertazzoniAdmin].some((element) => element == user.type) && !user.isViewer
      })
      // Distributor Rep
      acl.rule(['create', 'update', 'enable', 'disable', 'delete'], 'distributorRep', (user) => {
        return (
          [UserType.bertazzoniAdmin, UserType.countryAdmin].some(
            (element) => element == user.type
          ) && !user.isViewer
        )
      })
      acl.rule('read', 'distributorRep', (user) => {
        return [UserType.bertazzoniAdmin, UserType.countryAdmin].some(
          (element) => element == user.type
        )
      })
      // Sales Rep
      acl.rule(['create', 'update', 'enable', 'disable', 'delete'], 'salesRep', (user) => {
        return (
          [UserType.bertazzoniAdmin, UserType.countryAdmin, UserType.distributorRep].some(
            (element) => element == user.type
          ) && !user.isViewer
        )
      })
      acl.rule('read', 'salesRep', (user) => {
        return [UserType.bertazzoniAdmin, UserType.countryAdmin, UserType.distributorRep].some(
          (element) => element == user.type
        )
      })
      // Super Dealer Rep
      acl.rule(['create', 'update', 'enable', 'disable', 'delete'], 'superDealerRep', (user) => {
        return (
          [
            UserType.bertazzoniAdmin,
            UserType.countryAdmin,
            UserType.distributorRep,
            UserType.salesRep
          ].some((element) => element == user.type) && !user.isViewer
        )
      })
      acl.rule('read', 'superDealerRep', (user) => {
        return [
          UserType.bertazzoniAdmin,
          UserType.countryAdmin,
          UserType.distributorRep,
          UserType.salesRep
        ].some((element) => element == user.type)
      })
      // End User
      acl.rule(['create', 'update', 'enable', 'disable', 'delete'], 'seller', (user) => {
        return (
          [
            UserType.bertazzoniAdmin,
            UserType.countryAdmin,
            UserType.distributorRep,
            UserType.salesRep,
            UserType.superDealerRep
          ].some((element) => element == user.type) && !user.isViewer
        )
      })
      acl.rule('read', 'seller', (user) => {
        return [
          UserType.bertazzoniAdmin,
          UserType.countryAdmin,
          UserType.distributorRep,
          UserType.salesRep,
          UserType.superDealerRep
        ].some((element) => element == user.type)
      })
      acl.rule('export', 'seller', (user) => {
        return [UserType.bertazzoniAdmin].some((element) => element == user.type) && !user.isViewer
      })
      acl.rule(['create', 'read', 'update', 'delete'], 'communication', (user) => {
        return (
          [
            UserType.bertazzoniAdmin,
            UserType.countryAdmin,
            UserType.distributorRep,
            UserType.salesRep,
            UserType.superDealerRep
          ].some((element) => element == user.type) && !user.isViewer
        )
      })
      acl.rule(['create', 'read', 'export'], 'leaderboard', (user) => {
        return (
          [UserType.distributorRep, UserType.salesRep].some((element) => element == user.type) &&
          !user.isViewer
        )
      })
      // Country
      acl.rule(['create', 'read', 'enable', 'disable'], 'country', (user) => {
        return [UserType.bertazzoniAdmin].some((element) => element == user.type) && !user.isViewer
      })
      acl.rule(['assignTo'], 'country', (user) => {
        return [UserType.bertazzoniAdmin].some((element) => element == user.type)
      })
      // Tag
      acl.rule(['create', 'read'], 'tag', (user) => {
        return [UserType.bertazzoniAdmin].some((element) => element == user.type) && !user.isViewer
      })
      // Distributor
      acl.rule(['create', 'read', 'enable', 'disable'], 'distributor', (user) => {
        return [UserType.bertazzoniAdmin, UserType.countryAdmin].some(
          (element) => element == user.type
        )
      })
      acl.rule(['assignTo'], 'distributor', (user) => {
        return [UserType.bertazzoniAdmin, UserType.countryAdmin, UserType.distributorRep].some(
          (element) => element == user.type
        )
      })
      // Super Dealer
      acl.rule(['create', 'read', 'enable', 'disable'], 'superDealer', (user) => {
        return [
          UserType.bertazzoniAdmin,
          UserType.countryAdmin,
          UserType.distributorRep,
          UserType.salesRep
        ].some((element) => element == user.type)
      })
      acl.rule(['assignTo'], 'superDealer', (user) => {
        return [
          UserType.bertazzoniAdmin,
          UserType.countryAdmin,
          UserType.distributorRep,
          UserType.salesRep
        ].some((element) => element == user.type)
      })
      // Dealer
      acl.rule(['create', 'read', 'enable', 'disable'], 'dealer', (user) => {
        return [
          UserType.bertazzoniAdmin,
          UserType.countryAdmin,
          UserType.distributorRep,
          UserType.salesRep
        ].some((element) => element == user.type)
      })
      acl.rule(['assignTo'], 'dealer', (user) => {
        return [
          UserType.bertazzoniAdmin,
          UserType.countryAdmin,
          UserType.distributorRep,
          UserType.salesRep,
          UserType.superDealerRep
        ].some((element) => element == user.type)
      })
      // Location
      acl.rule(['create', 'read', 'update', 'delete', 'enable', 'disable'], 'location', (user) => {
        return [UserType.superDealerRep].some((element) => element == user.type)
      })
      // Country tag
      acl.rule(['associate'], 'countryTag', (user) => {
        return [UserType.bertazzoniAdmin].some((element) => element == user.type)
      })
      // Leaderbooard
      acl.rule(['read'], 'leaderboard', (user) => {
        return [UserType.distributorRep, UserType.salesRep].some((element) => element == user.type)
      })
      //another
      acl.rule(['listComm'], 'bertazzoniAdmin', (user) => {
        return [UserType.bertazzoniAdmin].some((element) => element == user.type)
      })
      acl.rule(['listComm'], 'countryAdmin', (user) => {
        return [UserType.bertazzoniAdmin, UserType.countryAdmin].some(
          (element) => element == user.type
        )
      })
      //Statistic
      acl.rule(['stat'], 'bertazzoniAdmin', (user) => {
        return [UserType.bertazzoniAdmin].some((element) => element == user.type)
      })
      acl.rule(['stat'], 'countryAdmin', (user) => {
        return [UserType.bertazzoniAdmin, UserType.countryAdmin].some(
          (element) => element == user.type
        )
      })
      // EventBus.$emit('permissionsLoad', true)
    }
  )
}
